/**
 * @Desc: 工具类
 */

import { Message } from "element-ui";

// localstorage工具
export const localStorageUtil = {
  getItem: function(key) {
    var value = localStorage.getItem(key);
    return value ? JSON.parse(value) : "";
  },
  setItem: function(key, value, exprise) {
    localStorage.setItem(key, JSON.stringify(value));
    if (exprise) {
      setInterval(() => {
        localStorage.getItem(key) && localStorage.removeItem(key);
      }, exprise);
    }
  },
  removeItem: function(key) {
    localStorage.getItem(key) && localStorage.removeItem(key);
  }
};

// sessionStorage工具
export const sessionStorageUtil = {
  getItem: function(key) {
    var value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : "";
  },
  setItem: function(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  removeItem: function(key) {
    sessionStorage.getItem(key) && sessionStorage.removeItem(key);
  }
};

/**
 * 获取当前浏览器的cookie是否开启
 *
 */
export function CookieEnable() {
  var result = false;
  if (navigator.cookiesEnabled) return true;

  document.cookie = "testcookie=yes;";

  var cookieSet = document.cookie;

  if (cookieSet.indexOf("testcookie=yes") > -1) result = true;

  document.cookie = "";

  return result;
}

// 文件大小格式化1
export function formatFileSize1(limit) {
  if (!limit) {
    return "0KB";
  }
  var size = "";
  if (limit < 0.9 * 1024) {
    //如果小于0.9KB转化成B
    size = limit.toFixed(2) + "B";
  } else if (limit < 0.9 * 1024 * 1024) {
    //如果小于0.9MB转化成KB
    size = (limit / 1024).toFixed(2) + "KB";
  } else if (limit < 0.9 * 1024 * 1024 * 1024) {
    //如果小于0.9GB转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + "MB";
  } else {
    //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
  }

  var sizestr = size + "";
  var len = sizestr.indexOf(".");
  var dec = sizestr.substr(len + 1, 2);
  if (dec == "00") {
    //当小数点后为00时 去掉小数部分
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
  }
  return sizestr;
}

// 验证手机号
export function validataPhone(value) {
  // let pattern = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[01235678]|18[0-9]|19[89])\d{8}$/;
  let pattern = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189|195]))\d{8}$/;

  if (null == value || value == "") {
    return false;
  } else if (!value.match(pattern) || value.length != 11) {
    return false;
  } else {
    return true;
  }
}
// 验证邮箱
export function validataEmail(value) {
  let pattern = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
  if (null == value || value == "") {
    return false;
  } else if (!value.match(pattern)) {
    return false;
  } else {
    return true;
  }
}

/**
 * 进入全屏
 */
export function enterFullScreen(ele) {
  var isFullscreen =
    document.fullScreen ||
    document.mozFullScreen ||
    document.webkitIsFullScreen;
  if (!isFullscreen) {
    //进入全屏,多重短路表达式
    (ele.requestFullscreen && ele.requestFullscreen()) ||
      (ele.mozRequestFullScreen && ele.mozRequestFullScreen()) ||
      (ele.webkitRequestFullscreen && ele.webkitRequestFullscreen()) ||
      (ele.msRequestFullscreen && ele.msRequestFullscreen());
  } else {
    //退出全屏,三目运算符
    document.exitFullscreen
      ? document.exitFullscreen()
      : document.mozCancelFullScreen
      ? document.mozCancelFullScreen()
      : document.webkitExitFullscreen
      ? document.webkitExitFullscreen()
      : "";
  }
}

/**
 * 退出全屏
 */
export function exitFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
  return false;
}

/**
 * 判断是否全屏
 */
export function isFullscreen() {
  if (document.fullscreenElement) {
    return true;
  } else if (document.msFullscreenElement) {
    return true;
  } else if (document.mozFullScreenElement) {
    return true;
  } else if (document.webkitFullscreenElement) {
    return true;
  } else {
    return false;
  }
}

/**
 * 时间格式化
 */
export const formatDateTime = (dt, type) => {
  let str = ""; //存储时间的字符串
  //获取年
  let year = dt.getFullYear();
  //获取月
  let month = dt.getMonth() + 1;
  //获取日
  let day = dt.getDate();
  //获取小时
  let hour = dt.getHours();
  //获取分钟
  let min = dt.getMinutes();
  //获取秒
  let sec = dt.getSeconds();
  //获取毫秒
  let ms = dt.getMilliseconds();
  //获取week
  let week = dt.getDay();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  hour = hour < 10 ? "0" + hour : hour;
  min = min < 10 ? "0" + min : min;
  sec = sec < 10 ? "0" + sec : sec;
  if (!type) {
    return (str =
      year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec);
  }
  if (type == "yyyy-MM-dd HH:mm:ss") {
    return (str =
      year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec);
  }
  if (type == "yyyy/MM/dd HH:mm:ss") {
    return (str =
      year + "/" + month + "/" + day + " " + hour + ":" + min + ":" + sec);
  }
  if (type == "yyyy-MM-dd HH:mm") {
    return (str = year + "-" + month + "-" + day + " " + hour + ":" + min);
  }
  if (type == "MM-dd") {
    return (str = month + "-" + day);
  }
  if (type == "MM-dd HH:mm") {
    return (str = month + "-" + day + " " + hour + ":" + min);
  }
  if (type == "yyyy-MM-dd HH:mm:ss") {
    return (str =
      year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec);
  }
  if (type == "yyyy/MM/dd HH:mm") {
    return (str = year + "/" + month + "/" + day + " " + hour + ":" + min);
  }
  if (type == "yyyy/MM/dd HH:mm:ss") {
    return (str =
      year + "/" + month + "/" + day + " " + hour + ":" + min + ":" + sec);
  }
  if (type == "yyyy-MM-dd") {
    return (str = year + "-" + month + "-" + day);
  }
  if (type == "yyyy/MM/dd") {
    return (str = year + "/" + month + "/" + day);
  }
  if (type == "yyyy年MM月dd日") {
    return (str = year + "年" + month + "月" + day + "日");
  }
  if (type == "yyyy年MM月dd日 HH时mm分ss秒") {
    return (str =
      year +
      "年" +
      month +
      "月" +
      day +
      "日" +
      hour +
      "时" +
      min +
      "分" +
      sec +
      "秒");
  }
  if (type == "yyyy年MM月dd日 HH时mm分") {
    return (str =
      year + "年" + month + "月" + day + "日" + hour + "时" + min + "分");
  }
  if (type == "yyyy年MM月dd日 HH:mm:ss") {
    return (str =
      year + "年" + month + "月" + day + "日" + hour + ":" + min + ":" + sec);
  }
  if (type == "yyyy年MM月dd日 HH:mm") {
    return (str = year + "年" + month + "月" + day + "日" + hour + ":" + min);
  }
  if (type == "HH:mm:ss") {
    return (str = hour + ":" + min + ":" + sec);
  }
  if (type == "HH:mm") {
    return (str = hour + ":" + min);
  }
  if (type == "yyyyMMddHHmmss") {
    return (str =
      year + "" + month + "" + day + "" + hour + "" + min + "" + sec);
  }
  if (type == "stamp") {
    let _num = (hour.toString() + min.toString() + sec.toString()) * 1 + ms;
    return _num;
  }
  if (type == "week") {
    let weeks = new Array(
      "周日",
      "周一",
      "周二",
      "周三",
      "周四",
      "周五",
      "周六"
    );
    return (str = weeks[week]);
  }
  if (type == "yyyy-MM-dd week") {
    let weeks = new Array(
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六"
    );
    return (str = year + "-" + month + "-" + day + " " + weeks[week]);
  }
  return (str =
    year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec);
};

/**
 * @desc debounce 函数防抖：所谓防抖，就是指触发事件后n秒内函数只能执行一次， 如果在n秒内又触发了此事件， 则会重新计算函数执行的时间
 * @param func 需要防抖的函数
 * @param wait 延时执行的毫秒数
 * @param immediate true-->立即执行 false-->非立即执行
 * **/
export function debounce(func, wait = 200, immediate = true) {
  let timeout;
  return function() {
    let _this = this;
    let args = arguments;
    if (timeout) {
      clearTimeout(timeout);
    }
    if (immediate) {
      let callNow = !timeout;
      timeout = setTimeout(() => {
        timeout = null;
      }, wait);
      if (callNow) {
        func.apply(_this, args);
      }
    } else {
      timeout = setTimeout(() => {
        func.apply(_this, args);
      }, wait);
    }
  };
}

/**
 * @desc throttle 函数节流：所谓节流，就是指连续触发事件但在n秒内只执行一次函数。节流会稀释函数的执行频率
 * @param func 需要节流的函数
 * @param wait 延迟执行的毫秒数
 * @param type type=1为时间戳版本（立即执行）， type=2为定时器版（非立即执行）
 * **/
export function throttle(func, wait = 200, type = 1) {
  if (type == 1) {
    let pre = 0;
    return function() {
      let _this = this;
      let args = arguments;
      let now = Date.now();
      if (now - pre > wait) {
        func.apply(_this, args);
        pre = now;
      }
    };
  } else {
    let timeout;
    return function() {
      let _this = this;
      let args = arguments;
      if (!timeout) {
        timeout = setTimeout(() => {
          func.apply(_this, args);
          timeout = null;
        }, wait);
      }
    };
  }
}

/**
 * @desc preventRepeatMessage 防止出现多个message提示 例如 error message: "登录过期，请重新登录",
 * @param messageType error | warning | info | success
 * @param repeatContent 防止多次出现的提示信息
 * @param message 提示信息
 * **/
export function preventRepeatMessage(
  messageType = "error",
  repeatContent = "登录过期",
  message = "登录过期，请重新登录"
) {
  setTimeout(() => {
    let ele = "",
      innerContent = "";
    switch (messageType) {
      case "error":
        ele = document.querySelector(".el-message--error .el-message__content");
        if (ele) {
          innerContent = document.querySelector(
            ".el-message--error .el-message__content"
          ).innerHTML;
        }
        break;
      case "warning":
        ele = document.querySelector(
          ".el-message--warning .el-message__content"
        );
        if (ele) {
          innerContent = document.querySelector(
            ".el-message--warning .el-message__content"
          ).innerHTML;
        }
        break;
      case "info":
        ele = document.querySelector(".el-message--info .el-message__content");
        if (ele) {
          innerContent = document.querySelector(
            ".el-message--info .el-message__content"
          ).innerHTML;
        }
        break;
      case "success":
        ele = document.querySelector(
          ".el-message--success .el-message__content"
        );
        if (ele) {
          innerContent = document.querySelector(
            ".el-message--success .el-message__content"
          ).innerHTML;
        }
        break;
      default:
        ele = document.querySelector(".el-message--error .el-message__content");
        if (ele) {
          innerContent = document.querySelector(
            ".el-message--error .el-message__content"
          ).innerHTML;
        }
        break;
    }
    if (ele) {
      if (innerContent && innerContent.indexOf(repeatContent) === -1) {
        if (
          message === "登录过期，请重新登录" &&
          document.querySelector(".el-message--info .el-message__content") &&
          document
            .querySelector(".el-message--info .el-message__content")
            .innerHTML.indexOf("在线编辑")
        ) {
          return;
        }
        Message({
          showClose: false,
          message: message,
          type: messageType,
          duration: 2000
        });
      }
    } else {
      Message({
        showClose: false,
        message: message,
        type: messageType,
        duration: 2000
      });
    }
  }, 100);
}

/**
 * @desc getFileSuffix 获取文件名后缀
 * @param str 文件名
 * @param caseWrite toLowerCase--转大写 toUpperCase--转小写 null--不转
 * **/
export function getFileSuffix(str = "", caseWrite) {
  let index = str.lastIndexOf(".");
  let _suffix = "";
  if (index !== -1) {
    _suffix = str.substr(index + 1, str.length);
    if (caseWrite === "toLowerCase") {
      _suffix = _suffix.toLowerCase();
    } else if (caseWrite === "toUpperCase") {
      _suffix = _suffix.toUpperCase();
    }
  }
  return _suffix;
}

/**
 * 时间差格式化
 * @param
 */
export const formatTimeDifference = (start, end, diff) => {
  let _start, _end, _diff;
  if (diff) {
    _diff = diff;
    if (!_diff) {
      return "";
    }
  } else {
    _start = isNaN(start) ? new Date(start).getTime() : start;
    _end = isNaN(end) ? new Date(end).getTime() : end;
    _diff = Math.abs(_start - _end);
    if (!_diff) {
      return "";
    }
  }
  let minutes = Math.floor(_diff / (60 * 1000));
  if (minutes <= 150) {
    // 如果小于等于150分钟 直接返回分钟数
    return minutes + "分钟";
  }
  //计算出相差天数
  let days = Math.floor(_diff / (24 * 3600 * 1000));

  //计算出小时数
  let leave1 = _diff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000));
  //计算相差分钟数
  let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  minutes = Math.floor(leave2 / (60 * 1000));
  //计算相差秒数
  let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
  let seconds = Math.round(leave3 / 1000);
  if (!days && !hours && !minutes && !days && seconds) {
    return "1分钟";
  }
  return `${days ? days + "天" : ""}${hours ? hours + "小时" : ""}${
    minutes ? minutes + "分钟" : ""
  }`;
};

/**
 * 补全日期区间
 * @param arr [start, end] = ['2019-10-12', '2020-09-01']
 */
export const dateRangeCompletion = (arr = []) => {
  let range = arr;
  if (range.length <= 1) {
    return range;
  }
  if (range.length > 2) {
    range = [range[0], range[range.length - 1]];
  }
  if (range[0] === range[1]) {
    return [range[0]];
  }
  let start, end;
  if (new Date(range[0]).getTime() > new Date(range[1]).getTime()) {
    start = range[1];
    end = range[0];
  } else if (new Date(range[0]).getTime() < new Date(range[1]).getTime()) {
    start = range[0];
    end = range[1];
  } else {
    return range;
  }

  let list = [];
  let ab = start.split("-");
  let ae = end.split("-");
  let db = new Date();
  db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
  let de = new Date();
  de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
  let unixDb = db.getTime() - 24 * 60 * 60 * 1000;
  let unixDe = de.getTime() - 24 * 60 * 60 * 1000;
  for (let k = unixDb; k <= unixDe; ) {
    k = k + 24 * 60 * 60 * 1000;
    list.push(formatDateTime(new Date(k), "yyyy-MM-dd"));
  }
  return list;
};

/**
 * 强制保留2位小数，不足补 0
 * **/
export const toDecimal = x => {
  var f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  var f = Math.round(x * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf(".");
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + 2) {
    s += "0";
  }
  return s;
};

/**
 * 判断是否是PC端
 * **/
export const isPC = x => {
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return false;
  } else {
    return true;
  }
};

/**
 * 判断是否上次选择的目录缓存的数据有变
 * type 1--同步课程 2--专题课
 * **/
export const LastSelectCheck = (type = 1) => {
  // if(type !== 1) { // topic switch
  //   return;
  // }
  let _accountInfo = localStorageUtil.getItem("accountInfo");
  if (!_accountInfo) {
    return;
  }
  let _l = null,
    _s = null,
    _c = null;
  if (type === 1) {
    _l = localStorageUtil.getItem(`${_accountInfo.phone}_lastSelect`);
    _s = localStorageUtil.getItem("subjects");
    // if(true) { // topic switch
    //   localStorageUtil.removeItem('subjects_t');
    //   localStorageUtil.removeItem('lastSelect_t');
    //   localStorageUtil.removeItem(`${_accountInfo.phone}_lastSelect_t`);
    //   localStorageUtil.removeItem('get_t');
    // }
  } else {
    _l = localStorageUtil.getItem(`${_accountInfo.phone}_lastSelect_t`);
    _s = localStorageUtil.getItem("subjects_t");
  }
  if (!_l) {
    return;
  }
  if (type === 2) {
    _c = _l.categories;
    if (!_c) {
      _c = {};
    }
  }
  for (let k in _l) {
    let o = _l[k];
    let item = _s[k];
    let change = false;
    if (o && item) {
      let editions = item.editions;
      let categories = null;
      if (type === 2 && _c[k]) {
        categories = item.categories ? item.categories : [];
        let i = categories.findIndex(ele => ele.categoryId == _c[k]);
        if (i === -1 && categories.length) {
          _c[k] = categories[0].categoryId;
          if (_l.subjectId == k) {
            _l.categoryId = categories[0].categoryId;
          }
          _l.categories = _c;
        }
      }
      if (o && o[0].editionId) {
        let has = editions[o[0].editionId];
        if (!has) {
          change = true;
          o[0] = {
            editionId: item.editionId,
            editionName: item.editionName
          };
          let vl = editions[item.editionId].volumes;
          if (vl) {
            o[1] = {
              volumeId: vl[0].volumeId,
              volumeName: vl[0].volumeName
            };
            if (o[2]) {
              o[2] = [];
            }
          }
        } else {
          let vl = editions[o[0].editionId].volumes;
          let i = vl.findIndex(ele => ele.volumeId == o[1].volumeId);
          if (i === -1) {
            change = true;
            o[1] = {
              volumeId: vl[0].volumeId,
              volumeName: vl[0].volumeName
            };
            if (o[2]) {
              o[2] = [];
            }
          }
        }
        if (change && _l.subjectId == k) {
          _l.editionId = o[0].editionId;
          _l.editionName = o[0].editionName;
          _l.volumeId = o[1].volumeId;
          _l.volumeName = o[1].volumeName;
          _l.chapterOneId = null;
          _l.chapterTwoId = null;
          _l.chapterThreeId = null;
        }
      }
    }
  }

  localStorageUtil.setItem(
    type === 1
      ? `${_accountInfo.phone}_lastSelect`
      : `${_accountInfo.phone}_lastSelect_t`,
    Object.assign({}, _l)
  );
};

/**
 *  图表颜色库
 * **/
export const colorLibrary = index => {
  // FFBD6B 417505
  const list = [
    "#05CAAF",
    "#6096FF",
    "#F1963A",
    "#FFEA33",
    "#FF5E5E",
    "#00C8FF",
    "#6ADFA5",
    "#9273E8",
    "#FF8357",
    "#84F8DE",
    "#CB69DF",
    "#FFBD6B",
    "#3F60F2",
    "#00A1FF",
    "#00B00E",
    "#FFDE00",
    "#8769FF",
    "#FF6DF4"
  ];
  // const list = ['#57AEFF', '#FBD955', '#2FC25B', '#975FE4', '#37CBCB', '#FF9999', '#FF6DF4', '#417505', '#9013FE', '#84F8DE', '#CB69DF', '#FFBD6B', '#3F60F2', '#00A1FF', '#00B00E', '#FFDE00', '#8769FF', '#FF6DF4'];
  // const list = ['#4ECB73', '#FBD955', '#76A6FF', '#FFBC9B', '#9e79d0', '#FF9999', '#FF6DF4', '#417505', '#9013FE', '#84F8DE', '#CB69DF', '#FFBD6B', '#3F60F2', '#00A1FF', '#00B00E', '#FFDE00', '#8769FF', '#FF6DF4'];
  let i = Number(index);
  if (i === NaN) {
    return list;
  } else if (i >= 0 && i < list.length) {
    return list[i];
  } else {
    return list;
  }
};

/**
 * @desc 下载前的下载文件名拼接
 * belongType 1--平台资源，2--共享资源，3--我的资源，4--我的备课，5--学习任务
 * type 资源类型 1:视频 2:交互课件 3:微课 4:题库子 5:教案 6:课件 7:教案课件 8:专题子资源 9:试卷库 21:专题包
 * source 文件来源  1:平台资源 2:共享资源 3:原创资源 11:教案模板 12:课件模板
 */
export function getDownlaodFileName(belongType, data = {}) {
  let {
    resourceTitle,
    resourceId,
    fileUrl,
    type,
    source,
    fileName,
    fileMime
  } = data;
  let _suffix = "",
    _suffix1 = "",
    _downloadName = "";
  if (fileUrl) {
    let i = fileUrl.lastIndexOf(".");
    if (i !== -1) {
      _suffix = fileUrl.substring(i + 1);
    }
  }
  if (belongType === 1) {
    if (type === 21) {
      _downloadName = resourceTitle + ".zip";
    } else {
      _downloadName = resourceTitle + "." + _suffix;
    }
  } else if (belongType === 2) {
    if (type === 7 || type === 21) {
      _downloadName = resourceTitle + ".zip";
    } else {
      _downloadName = resourceTitle + "." + _suffix;
    }
  } else if (belongType === 3) {
    let _resourceTitle = "";
    if (fileName) {
      let i = fileName.lastIndexOf(".");
      if (i !== -1) {
        _suffix1 = fileName.substring(i + 1);
        if (_suffix1 === fileMime) {
          _resourceTitle = fileName.substring(0, i);
        } else {
          _resourceTitle = fileName;
        }
      } else {
        _resourceTitle = fileName;
      }
    }
    if (source === 1) {
      _downloadName = _resourceTitle + "." + fileMime;
    } else if (source === 2) {
      if (fileMime === "教案课件") {
        _downloadName = fileName + ".zip";
      } else {
        _downloadName = _resourceTitle + "." + fileMime;
      }
    } else if (source === 3) {
      _downloadName = _resourceTitle + "." + fileMime;
    }
  } else if (belongType === 4) {
    if (type === 5) {
      _downloadName = resourceTitle + ".docx";
    } else if (type === 6) {
      _downloadName = resourceTitle + ".pptx";
    } else if (type === 7) {
      _downloadName = resourceTitle + ".zip";
    }
  }
  return _downloadName;
}

/**
 * 获取当前页面的类型
 */
export function GetPageType(val) {
  if (!val) {
    return "home";
  }
  let { path } = val;
  let pageTypeList = [
    "home",
    "12156",
    "12158",
    "12157",
    "12155",
    "12159",
    "personal-center"
  ];
  let l = path.split("/");
  if (l.length > 1) {
    if (!pageTypeList.includes(l[1])) {
      return "home";
    } else {
      return l[1];
    }
  } else {
    return "home";
  }
}

/**
 * 获取鼠标位置
 */
export function GetMousePos(event) {
  let e = event || window.event;
  let scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
  let scrollY = document.documentElement.scrollTop || document.body.scrollTop;
  let x = e.pageX || e.clientX + scrollX;
  let y = e.pageY || e.clientY + scrollY;
  return { x: x, y: y };
}

/**
 * 获取随机数
 */
export function GetRandomNum(m, n) {
  let num = Math.floor(Math.random() * (m - n) + n);
  return num;
}

/**
 * 判断是否为数字
 * decimal 是否支持小数
 */
export function IsNumber(data, decimal = false) {
  var reg = decimal ? /^[0-9]+.?[0-9]*$/ : /^[0-9]*$/;
  if (reg.test(data)) {
    return true;
  }
  return false;
}
