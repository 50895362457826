<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>
import store from "store";
export default {
  name: "app",
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true
    };
  },
  methods: {
    reload(params) {
      store.commit("setNoAuthority", false);
      store.commit("setServerError", false);
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },

    /**
     * 屏幕缩放事件 / 16比10适配
     */
    _resizeEvent: function() {
      var d = document;
      var b = d.body;
      var c = b.className || "";
      var w = (d.documentElement || b).clientWidth;
      var h = (d.documentElement || b).clientHeight;
      // 设置对应区间的宽度类名
      if (window.screen.width / window.screen.height == 16 / 10) {
        // if (h >= 900) {
        c = "h16-10";
      } else {
        c = "";
      }
      b.classNam;
      b.className = c;
    }
  },
  mounted() {
    // window监听屏幕缩放事件
    window.addEventListener("resize", this._resizeEvent);
    if (window.screen.width / window.screen.height == 16 / 10) {
      this._resizeEvent();
    }
  },
  created() {
    store.dispatch("getVersion");
  }
};
</script>
<style lang="less">
body {
  // height: 100vh;
  // overflow-y: scroll;
}
#app {
  font-family: "DFPFangYuanW7";
  width: 100%;
  min-width: 1200px;
  height: 100vh;
  overflow-y: scroll;
}
</style>
