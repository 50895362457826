import { debounce, localStorageUtil, formatDateTime } from "utils/util";
import { reqLogout, reqGetInformation } from "api/personal-center";
import { reqSyncHistory } from 'api/resource';
import { requestGetVersionData } from "api/version";

import store from 'store';
import bus from "utils/bus";

/** 
 * 获得用户信息 
 */
export const getUserInfo = debounce(({ commit }) => {
  let obj = {};
  return reqGetInformation(obj).then(res => {
    if(res.code === 0) {
      commit('SET_USER', res.data);
    }
  }).catch(err => {
    console.log('获取基本资料失败', err);
  });
}, 300);

/** 
 * 退出登录 
 */
export function setLogout({ commit, vm }) {
  let obj = {};
  return reqLogout(obj).then(res => {
    if(res.code === 0) {
      commit('LOGOUT', res.data);
    }
    bus.$emit('Reload');
  }).catch(err => {
    console.log('退出登录失败', err);
    bus.$emit('Reload');
  });
}

/** 
 * 同步播放记录
 */
export function syncHistory({ commit, vm }) {
  let localHistory = localStorageUtil.getItem('localHistory');
  if(!localHistory ||!localHistory.length) {
    commit('SYNC_HISTORY', true);
    return;
  }
  let l = [];
  for(let item of localHistory) {
    l.push({
      resourcePackingId: item.resourcePackingId,
      playTime: item.playedTime && item.playedTime.toString().indexOf('-') !== -1 ? item.playedTime : formatDateTime(new Date(item.playedTime), 'yyyy-MM-dd HH:mm:ss'),
    });
  }
  let obj = {};
  return reqSyncHistory(l).then(res => {
    commit('SYNC_HISTORY', true);
  }).catch(err => {
    commit('SYNC_HISTORY', true);
    console.log('同步播放记录失败', err);
  });
}


/** 
 * 获取版本信息 
 */
export function getVersion({ commit, vm }) {
  return requestGetVersionData().then(response => {
    commit('SET_VERSION', response);
  }).catch(err => {
    console.log('getVersion err',err);
  });
}