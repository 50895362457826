/**
 * 网络请求相关配置
 */

let NODE_ENV = process.env.NODE_ENV
let baseURL = ''
let trackBaseURL = ''
let FeVersion = "976254e6ac03b7f993498dd25a53cead8a487a30"
let versionMessage = "976254e - renning, Wed Aug 14 18:25:12 2024 +0800:用户系统隐藏"  
let _ENV = "@BUILD.ENV@"
let productType = "@PRODUCT.TYPE@"

// 生产环境
if (NODE_ENV === 'production') {
  baseURL = 'https://family-server.viewshare.cn'
  trackBaseURL = 'https://track-server.xiaoshanyun.com'
}

// 开发环境
if (NODE_ENV === 'development') {
  baseURL = 'https://family-server.viewshare.cn'
  trackBaseURL = 'https://track-server.xiaoshanyun.com'
}

const auth = {
  username: 'viewshare',
  password: 'family'
}

export {
  baseURL,
  trackBaseURL,
  auth,
  versionMessage,
  _ENV,
  FeVersion,
  productType
}