/*
 * VideoStatistics
 * @v2.0.0
 * 2020-11.6 zhujianyun
 * @Desc: Statistics of video playing duration
 */
let stamp = '', 
  urlPath = '', 
  updateTimer = 5 * 1000, 
  sendTimer = 5 * 60 * 1000, 
  errorLimit = 10, 
  errorNextInterval = 2 * 60 * 60 * 1000, 
  videoList = [], 
  trackURL = 'https://track-server.xiaoshanyun.com/api/v1/tracking', 
  userType = 2,
  needUserId = true,
  wxInit = false, 
  callBack = null, 
  device = 'pc', 
  _this = null;
export default function VideoStatistics(params = {}) {
// let VideoStatistics = function (params = {}) {
  let boxId = params.boxId ? params.boxId : '';
  updateTimer = params.updateTimer ? params.updateTimer : updateTimer;
  sendTimer = params.sendTimer ? params.sendTimer : sendTimer;
  errorLimit = params.errorLimit ? params.errorLimit : errorLimit;
  errorNextInterval = params.errorNextInterval ? params.errorNextInterval : errorNextInterval;
  device = params.device ? params.device : device;
  trackURL = params.trackURL ? params.trackURL : trackURL;
  userType = params.userType ? params.userType : userType;
  needUserId = typeof params.needUserId === 'boolean' ? params.needUserId : needUserId;
  callBack = params.callback ? params.callback : callBack;
  if (device === 'pc') {
    let selector = 'video';
    if (boxId) {
      selector = boxId + ' video';
    }
    videoList = document.querySelectorAll(selector);
  } else {
    wxInit = true;
  }
  _this = this;
  let _customerid = null;
  if (params.init) {
    _customerid = params.init.customerid || params.init.customerId;
    this.init(params.init);
  }else if(params.otherTypeInit) {
    _customerid = params.otherTypeInit.customerid || params.otherTypeInit.customerId;
    eventVideoStorage({type: params.otherTypeInit.type}, params.otherTypeInit);
  }
  if(params.baiduTrackUrl && _customerid) {
    AddBaiduTracking(params.baiduTrackUrl, _customerid);
  }
};

VideoStatistics.prototype.init = function (params) {
  if (device === 'pc') {
    for (let i = 0; i < videoList.length; i++) {
      let video = videoList[i];
      video.setAttribute('init', true);
      if (video.getAttribute('videouid')) {
        video.setAttribute('videouid', '');
      }

      // 事件移除
      video.removeEventListener('play', eventVideoStorage);
      video.removeEventListener('timeupdate', eventVideoStorage);
      video.removeEventListener('pause', eventVideoStorage);
      video.removeEventListener('ended', eventVideoStorage);
      // play：播放监听
      video.addEventListener('play', (e) => eventVideoStorage(e, params));
      // timeupdate：播放中监听
      // video.addEventListener('timeupdate', throttle(eventVideoStorage, 500));
      video.addEventListener('timeupdate', throttle((e) => eventVideoStorage(e, params), 500));
      // pause：暂停监听
      video.addEventListener('pause', (e) => eventVideoStorage(e, params));
      // ended：播放结束
      video.addEventListener('ended', (e) => eventVideoStorage(e, params));
      if(video) {
        console.log('video-statistics added successfully');
      }
    }
  }else {
    wxInit = true;
  }
};

// 以下四个事件是针对 小程序中的vidoe标签不能用addEventListener添加监听事件 所添加的
/**
 * @desc play 当开始/继续播放时触发 play 事件
 * **/
VideoStatistics.prototype.play = function (params) {
  return setVideoStatistics(Object.assign({}, params));
  // console.log('play', params.resourceid);
};
/**
 * @desc pause 当暂停播放时触发 pause 事件
 * **/
VideoStatistics.prototype.pause = function (params) {
  return setVideoStatistics(Object.assign({}, params));
  // console.log('pause', params.resourceid);
};
/**
 * @desc timeupdate 播放进度变化时触发
 * **/
VideoStatistics.prototype.timeupdate = function (params) {
  return setVideoStatistics(Object.assign({}, params));
  // console.log('timeupdate', params.resourceid);
};
/**
 * @desc ended 当播放到末尾时触发 ended 事件
 * **/
VideoStatistics.prototype.ended = function (params) {
  return setVideoStatistics(Object.assign({}, params));
  // console.log('ended', params.resourceid);
};

/**
 * @desc operateReturnHttp 请求返回后的数据处理
 * **/
VideoStatistics.prototype.operateReturnHttp = function (res, data) {
  // console.log('code', res);
  let nowDate = new Date().getTime();
  let videoStorage = storageUtil('get', 'videoStorage');
  let sendList = [];
  if (!videoStorage || !data) {
    return;
  }
  if (videoStorage.sendList) {
    sendList = videoStorage.sendList;
  }
  let i = sendList.findIndex(ele => ele.sendTime === data.sendTime);
  if (res === 'success') {
    videoStorage.errorNum = 0;
    if (i !== -1) {
      sendList.splice(i, 1);
      videoStorage.sendList = [...sendList];
    }
    storageUtil('set', 'videoStorage', videoStorage);
    return;
  }
  videoStorage.errorNum++;
  videoStorage.errorTime = nowDate;
  if (i !== -1) {
    sendList[i].state = 'error';
    for(let item of sendList[i].data) {
      if(item.errorNum) {
        item.errorNum++;
      }else {
        item.errorNum = 1;
      }
    }
  } else {
    for(let item of data.data) {
      if(item.errorNum) {
        item.errorNum++;
      }else {
        item.errorNum = 1;
      }
    }
    sendList.push(Object.assign({}, data, { state: 'error' }));
  }
  videoStorage.sendList = [...sendList];
  storageUtil('set', 'videoStorage', videoStorage);
};

/**
 * @desc switch 单页面视频切换 小程序使用
 * **/
VideoStatistics.prototype.switch = function (params) {
  setVideoStatistics({
    type: 'switch',
    resourceid: params.resourceid
  });
  console.log('switch', params.resourceid);
};

/**
 * @desc close 离开播放页时检测数据 小程序使用
 * **/
VideoStatistics.prototype.close = function (needSend = false) {
  let nowDate = new Date().getTime();
  let videoStorage = storageUtil('get', 'videoStorage');
  if (!videoStorage) {
    return;
  }
  let storageObj = {};
  if (videoStorage.storageObj) {
    storageObj = videoStorage.storageObj;
  }
  for (let key in storageObj) {
    if (storageObj[key].type === 'play') {
      storageObj[key].playingTime += computePlayTime(nowDate, storageObj[key].startTime, storageObj[key].playbackRate);
      storageObj[key].type = 'pause';
      storageObj[key].playTime = nowDate;
      storageObj[key].startTime = nowDate;
      storageObj[key].updatingTime = nowDate;
    }
  }
  videoStorage.storageObj = storageObj;
  storageUtil('set', 'videoStorage', videoStorage);
  if(needSend) {
    sendStorage();
  }
};


/**
 * @desc eventVideoStorage 添加监听事件
 * @param e 
 * **/
function eventVideoStorage(e, params) {
  let target = e.target;
  let id = target ? target.getAttribute('resourceid') : null;
  if(!id) {
    id = params.resourceid || params.resourceId;
  }
  let obj = {
    target,
    type: e.type,
    resourceid: id,
    userid: params.userid || params.userId ? (params.userid || params.userId) : 0,
    customerid: params.customerid || params.customerId ? (params.customerid || params.customerId) : 0,
    schoolid: params.schoolid || params.schoolId ? (params.schoolid || params.schoolId) : 0,
    level: params.level || params.levelid || params.levelId ? (params.level || params.levelid || params.levelId) : 0,
    subject: params.subject || params.subjectid || params.subjectId ? (params.subject || params.subjectid || params.subjectId) : 0,
    currentTime: target && target.currentTime ? target.currentTime : 0,
    duration: target && target.duration ? target.duration : 0,
    playbackRate: target && target.playbackRate ? target.playbackRate : 1
  };
  setVideoStatistics(obj);
};
/**
 * @desc setVideoStatistics 存储视频播放数据
 * @param params 所需参数
 * **/
function setVideoStatistics(params) {
  if (!params) {
    return;
  }
  let nowDate = new Date().getTime();
  let videoStorage = storageUtil('get', 'videoStorage'), storageObj = {}, sendList = [], uid = '';
  if (!videoStorage) {
    videoStorage = {};
  }
  if (videoStorage.storageObj) {
    storageObj = videoStorage.storageObj;
  }
  if (videoStorage.sendList) {
    sendList = videoStorage.sendList;
  }
  let { target, type, resourceid, userid, customerid, schoolid, level, subject, currentTime, duration, playbackRate } = params;
  if(type === 'preview' || type === 'edit' || type === 'download' || device === 'wx') {
    uid = resourceid;
  } else if (device === 'pc') {
    uid = target.getAttribute('videouid');
  }
  let item = storageObj[uid];
  if (item) {
    let _lastPlayTime = item.playTime;
    switch (type) {
      case 'switch': { // 同个页面进行切换资源时
        if (item.type === 'play') {
          item.playingTime += computePlayTime(nowDate, item.startTime, playbackRate ? playbackRate : item.playbackRate);
          item.startTime = nowDate;
          item.updatingTime = nowDate;
          item.type = 'pause';
        } else {
          return;
        }
        break;
      }
      case 'play': {
        item.playTime = nowDate;
        item.startTime = nowDate;
        item.updatingTime = nowDate;
        item.type = type;
        // 重播时
        if (device === 'pc' && target.getAttribute('init')) {
          item.playCount = item.playCount ? item.playCount + 1 : 1;
          target.setAttribute('init', '');
        } else if (device === 'wx' && wxInit) {
          item.playCount = item.playCount ? item.playCount + 1 : 1;
          wxInit = false;
        }
        break;
      }
      case 'pause': {
        if (duration - currentTime < 0.1) {
          console.log('is ended');
          if (device === 'pc') {
            target.setAttribute('init', 'true');
          } else if (device === 'wx') {
            wxInit = true;
          }
        }
        item.playingTime += computePlayTime(nowDate, item.startTime, playbackRate);
        item.startTime = nowDate;
        item.updatingTime = nowDate;
        item.type = type;
        break;
      }
      case 'timeupdate': {
        let _playing = nowDate - item.updatingTime;
        if (_playing >= updateTimer && item.type === 'play' && duration) {
          item.playingTime += computePlayTime(nowDate, item.startTime, playbackRate);
          item.startTime = nowDate;
          item.updatingTime = nowDate;
          // item.type = type;
          // console.log('timeupdate ' + uid + ': ', _playing, item.playingTime);
        } else {
          return;
        }
        break;
      }
      case 'ended': {
        item.startTime = nowDate;
        item.updatingTime = nowDate;
        item.type = type;
        if (device === 'pc') {
          target.setAttribute('init', 'true');
          console.log('ended');
        } else if (device === 'wx') {
          wxInit = true;
        }
        break;
      }
      case 'preview': { // 非视频资源统计 预览次数
        item.viewCount = item.viewCount ? item.viewCount + 1 : 1;
        item.type = type;
        break;
      }
      case 'edit': { // 非视频资源统计 备课编辑次数
        item.editCount = item.editCount ? item.editCount + 1 : 1;
        item.type = type;
        break;
      }
      case 'download': { // 资源统计 下载次数
        item.downloadCount = item.downloadCount ? item.downloadCount + 1 : 1;
        item.type = type;
        break;
      }
    }
    if(type === 'play' || type === 'timeupdate') {
      let _now = formatDateTime(new Date(nowDate), 'yyyy-MM-dd');
      let _p = formatDateTime(new Date(_lastPlayTime), 'yyyy-MM-dd');
      if(type === 'play' && _now !== _p && !item.playCount) {
        item.playCount = 1;
      }else if(type === 'timeupdate' && item.rejoin && !item.playCount) {
        item.playCount = 1;
        item.rejoin = false;
      }
    }
    storageObj[uid] = item;
    videoStorage.storageObj = storageObj;
    videoStorage.sendList = sendList;
  } else if (type === 'play') {
    item = {
      resourceid: resourceid,
      userid: userid,
      customerid: customerid,
      schoolid: schoolid,
      level: level,
      subject: subject,
      playTime: nowDate,
      startTime: nowDate,
      updatingTime: nowDate,
      playingTime: 0,
      type: 'play',
      currentTime: currentTime,
      playbackRate: playbackRate,
      duration: duration
    };

    if (device === 'pc' && target.getAttribute('init')) {
      let idN = getUID(storageObj, resourceid);
      item.playCount = item.playCount ? item.playCount + 1 : 1;
      uid = resourceid + '_' + idN;
      target.setAttribute('init', '');
      target.setAttribute('videouid', uid);
    } else if (device === 'wx' && wxInit) {
      item.playCount = item.playCount ? item.playCount + 1 : 1;
      wxInit = false;
    }
    storageObj[uid] = Object.assign({}, item);
    videoStorage = {
      errorNum: videoStorage.errorNum ? videoStorage.errorNum : 0,
      errorTime: videoStorage.errorTime ? videoStorage.errorTime : nowDate,
      updateTime: videoStorage.updateTime ? videoStorage.updateTime : nowDate,
      storageObj: storageObj,
      sendList: videoStorage.sendList,
    }
  } else if(type === 'preview' || type === 'edit' || type === 'download') {
    item = {
      resourceid: resourceid,
      userid: userid,
      customerid: customerid,
      schoolid: schoolid,
      level: level,
      subject: subject,
      playTime: nowDate,
      startTime: nowDate,
      updatingTime: nowDate,
      playingTime: 0,
      type: type,
    };
    if(type === 'preview') {
      item.viewCount = 1;
    }else if(type === 'edit') {
      item.editCount = 1;
    }else if(type === 'download') {
      item.downloadCount = 1;
    }
    storageObj[uid] = Object.assign({}, item);
    videoStorage = {
      errorNum: videoStorage.errorNum ? videoStorage.errorNum : 0,
      errorTime: videoStorage.errorTime ? videoStorage.errorTime : nowDate,
      updateTime: videoStorage.updateTime ? videoStorage.updateTime : nowDate,
      storageObj: storageObj,
      sendList: videoStorage.sendList,
    }
  } else {
    return;
  }
  storageUtil('set', 'videoStorage', videoStorage);
  computeSend();
};


/**
 * @desc 获取设置vidoeuid
 * **/
function getUID(params, id) {
  let idL = [], idN = 1;
  for (let k in params) {
    let _k = k.split('_');
    if (_k[0] == id && _k[1]) {
      idL.push(Number(_k[1]));
    }
  }

  if (idL.length) {
    idL = bubbleSort(idL, 'desc');
    idN = idL[0] * 1 + 1;
  }
  return idN;
};

/**
 * @desc computePlayTime 计算播放时间差
 * @param timerTime 定时器间隔时间 单位ms
 * **/
function computePlayTime(nowDate, startTime, playbackRate = 1) {
  let _diff = nowDate - startTime;
  if (_diff > updateTimer + 1000) {
    _diff = updateTimer / 2 - 400;
  }
  _diff = _diff * playbackRate;
  return _diff;
};

/**
 * @desc computeSend 计算是否需要发送数据
 * @param timerTime 定时器间隔时间 单位ms
 * **/
function computeSend() {
  let nowDate = new Date().getTime();
  let videoStorage = storageUtil('get', 'videoStorage'), storageObj = {}, sendList = [];
  if (!videoStorage) {
    return;
  }
  if (videoStorage.storageObj) {
    storageObj = videoStorage.storageObj;
  }
  if (videoStorage.sendList) {
    sendList = videoStorage.sendList;
  }
  if (!Object.keys(storageObj).length && !sendList.length) {
    return;
  }
  let { updateTime } = videoStorage;
  if (nowDate - updateTime > sendTimer) { // need send
    sendStorage();
  }
};


/**
 * @desc sendStorage 处理需要发送给后台的视频统计数据
 * **/
function sendStorage() {
  let nowDate = new Date().getTime();
  let videoStorage = storageUtil('get', 'videoStorage');
  let storageObj = {};
  let sendList = [];
  if (!videoStorage) {
    return;
  }
  if (videoStorage.storageObj) {
    storageObj = videoStorage.storageObj;
  }
  if (videoStorage.sendList) {
    sendList = videoStorage.sendList;
  }
  if (!Object.keys(storageObj).length && !sendList.length) {
    videoStorage.updateTime = nowDate;
    storageUtil('set', 'videoStorage', videoStorage);
    return videoStorage;
  }
  if (videoStorage.errorNum > errorLimit) {
    videoStorage.diffErrorTime = nowDate - (videoStorage.errorTime ? videoStorage.errorTime : nowDate);
    if (videoStorage.diffErrorTime < errorNextInterval) {
      videoStorage.updateTime = nowDate;
      storageUtil('set', 'videoStorage', videoStorage);
      return;
    } else {
      videoStorage.errorNum = 0;
    }
  }
  let _addObj = {}, sendingObj = { sendTime: nowDate, state: 'ing', data: [] };

  for (let key in storageObj) {
    let _obj = {};
    let item = storageObj[key];
    let time_now = formatDateTime(new Date(nowDate), 'yyyy-MM-dd');
    let time_last_play = formatDateTime(new Date(item.playTime), 'yyyy-MM-dd');
    let time_last = formatDateTime(new Date(item.startTime), 'yyyy-MM-dd');
    if (item.type === 'play') {
      _obj = {
        resourceid: item.resourceid,
        userid: item.userid,
        customerid: item.customerid,
        schoolid: item.schoolid,
        level: item.level,
        subject: item.subject,
        playTime: nowDate,
        startTime: nowDate,
        updatingTime: nowDate,
        playingTime: 0,
        type: 'play',
        currentTime: item.currentTime,
        playbackRate: item.playbackRate,
        duration: item.duration
      };
      if(time_now !== time_last_play) {
        _obj.rejoin = true;
      }
      if (item.playingTime) {
        item.zeroNum = 0;
      } else {
        item.zeroNum = item.zeroNum ? item.zeroNum + 1 : 1;
      }
      _obj.zeroNum = item.zeroNum;
      if (item.zeroNum <= 1) {
        item.playingTime += computePlayTime(nowDate, item.startTime, item.playbackRate);
      }
    } else {
      item.zeroNum = 0;
    }
    if ((item.playingTime || item.viewCount || item.editCount || item.downloadCount) && item.zeroNum <= 1) {
      sendingObj.data.push(item);
    }
    if(item.type === 'play' && item.zeroNum <= 1) {
      if(!item.playingTime) {
        _obj.playCount = item.playCount ? item.playCount : 0;
      }
      if(time_now === time_last || (nowDate - item.startTime < updateTimer + 1000)) {
        _addObj[key] = _obj;
      }else {
        console.log('play && Cross Days', item.resourceid);
      }
    }
  }
  let _sendList = [];
  for (let i = 0; i < sendList.length; i++) {
    let item = sendList[i];
    if (item.data) {
      if ((item.state === 'ing' && (nowDate - item.sendTime > 11 * 1000)) || item.state === 'error' || !item.data.length) {
        sendingObj.data.push(...item.data);
      } else {
        _sendList.push(item);
      }
    }
  }

  videoStorage.updateTime = nowDate;
  videoStorage.storageObj = _addObj;
  videoStorage.sendList = _sendList;
  // console.log('send:',formatDateTime(), videoStorage);
  storageUtil('set', 'videoStorage', videoStorage);
  storageSizeLimit();
  if (sendingObj.data.length) {
    sendStorageHttp(sendingObj);
  }
};

/**
 * @desc 存储数据过大时 删除错误数据
 * @param  
 * **/
function storageSizeLimit() {
  let _size = storageUtil('size', 'videoStorage');
  if (_size < 1024) { // 1024
    return;
  }
  let videoStorage = storageUtil('get', 'videoStorage');
  let storageObj = {};
  let sendList = [];
  if (!videoStorage) {
    return;
  }
  if (videoStorage.storageObj) {
    storageObj = videoStorage.storageObj;
  }
  if (videoStorage.sendList) {
    sendList = videoStorage.sendList;
  }
  let _delNum = 0;
  if (!sendList || !sendList.length) {
    storageObj = {};
  } else {
    for (let item of sendList) {
      if (item.state === 'error' && item.data && item.data.length) {
        for (let i = 0; i < item.data.length; i++) {
          if (item.data[i].errorNum) {
            item.data.splice(i, 1);
            _delNum++;
            i--;
          }
        }
      }
    }
    if (_delNum < 100) {
      sendList = [];
    }
  }
  videoStorage.storageObj = storageObj;
  videoStorage.sendList = sendList;
  // console.log('storageSizeLimit', videoStorage);
  storageUtil('set', 'videoStorage', videoStorage);
};

/**
 * @desc 回调 发送给后台的ajax请求
 * @param  
 * **/
function sendStorageHttp(params) {
  let _params = JSON.parse(JSON.stringify(params));
  let _list = dataProcess(JSON.parse(JSON.stringify(_params.data)));
  // console.log('sendData:',formatDateTime(), _list);
  if(!_list.length) {
    let videoStorage = storageUtil('get', 'videoStorage');
    if (!videoStorage) {
      return;
    }
    videoStorage.updateTime = new Date().getTime();
    storageUtil('set', 'videoStorage', videoStorage);
    return;
  }
  if(callBack) {
    callBack(_params, _list);
  }else {
    reqVideoInfo({userType: userType, trackingDataList: _list}).then(res => {
      // console.log('call-------------back res', res.code);
      _this.operateReturnHttp(res.code === 0 || res.code === 16201 ? 'success' : 'fail', _params);
    }).catch(err => {
      // console.log('call-------------back err', err);
      _this.operateReturnHttp('fail', _params);
    });
  }
};


/**
 * @desc 数据整合
 * @param  
 * **/
function dataProcess(data) {
  let list = [...data], list1 = [];
  for (let i = 0; i < list.length; i++) {
    for (let j = i + 1; j < list.length; j++) {
      if (list[i].resourceid == list[j].resourceid && list[i].userid == list[j].userid) {
        let i_playTime = formatDateTime(new Date(list[i].playTime), 'yyyy-MM-dd');
        let j_playTime = formatDateTime(new Date(list[j].playTime), 'yyyy-MM-dd');
        if (i_playTime === j_playTime) {
          list[i].playCount = list[i].playCount ? list[i].playCount : 0;
          list[j].playCount = list[j].playCount ? list[j].playCount : 0;
          list[i].viewCount = list[i].viewCount ? list[i].viewCount : 0;
          list[j].viewCount = list[j].viewCount ? list[j].viewCount : 0;
          list[i].editCount = list[i].editCount ? list[i].editCount : 0;
          list[j].editCount = list[j].editCount ? list[j].editCount : 0;
          list[i].downloadCount = list[i].downloadCount ? list[i].downloadCount : 0;
          list[j].downloadCount = list[j].downloadCount ? list[j].downloadCount : 0;
          list[i].playCount += list[j].playCount;
          list[i].viewCount += list[j].viewCount;
          list[i].editCount += list[j].editCount;
          list[i].downloadCount += list[j].downloadCount;
          list[i].playingTime += list[j].playingTime;
          list.splice(j, 1);
          j--;
        }
      }
    }
  }
  for(let x of list) {
    let obj = {
      "resourceId": x.resourceid ? Number(x.resourceid) : 0,
      "customerId": x.customerid ? Number(x.customerid) : 0,
      "schoolId": x.schoolid ? Number(x.schoolid) : 0,
      "userId": x.userid ? Number(x.userid) : 0,
      "level": x.level ? Number(x.level) : 0,
      "subject": x.subject ? Number(x.subject) : 0,
      "trackingDate": x.playTime ? formatDateTime(new Date(x.playTime), 'yyyy-MM-dd') : formatDateTime(new Date(), 'yyyy-MM-dd'),
      "platform": device === 'pc' ? 1 : (device === 'wx' ? 2 : 3)
    }
    if(x.viewCount) {
      obj.viewCount = x.viewCount;
    }
    if(x.editCount) {
      obj.editCount = x.editCount;
    }
    if(x.downloadCount) {
      obj.downloadCount = x.downloadCount;
    }
    let _time = x.playingTime;
    if(_time < 200 && !x.playCount) {
      _time = 0;
    }else {
      if(_time < 200) {
        _time = 200;
      }
      _time = Math.round((_time - 200) / 1000);
      if(!_time) {
        _time = 1;
      }
    }
    if(_time) {
      obj.playCount = x.playCount ? x.playCount : 0;
      obj.duration = _time;
    }
    if ((_time || obj.viewCount || obj.editCount || obj.downloadCount) && x.customerid && (needUserId ? x.userid : true)) {
      list1.push(obj);
    }
  }

  return list1;
};


// 发送统计数据
function reqVideoInfo(data) {
  return new Promise((resolve, reject) => {
    // setTimeout(() => {
    //   let obj = {
    //     "code": new Date().getTime() % 2 === 1 ? 500 : 500,
    //     "message": "success",
    //     "serverTimestamp": 1604641445761,
    //     "data": true
    //   }
    //   if (obj.code === 0) {
    //     resolve(obj);
    //   } else {
    //     reject(obj);
    //   }
    // }, 500);
    // return;

    //调用ajax函数
    ajax({
      url: trackURL,
      type: 'POST',
      dataType: 'json',
      data: data,
      success: function (response, xml) {
        //请求成功后执行的代码
        let res = JSON.parse(response);
        resolve(res);
        // console.log('-----success', res);
      },
      error: function (status) {
        //失败后执行的代码
        reject(status);
        // console.log('-----error', status);
      }
    });
  });

};


//创建ajax函数
function ajax(option) {
  let options = option || {};
  options.type = (options.type || 'GET').toUpperCase();
  options.dataType = options.dataType || 'json';
  let params = JSON.stringify(options.data);

  //创建-第一步
  var xhr;
  //非IE6
  if (window.XMLHttpRequest) {
    xhr = new XMLHttpRequest();
  } else {
    //ie6及其以下版本浏览器
    xhr = ActiveXObject('Microsoft.XMLHTTP');
  }

  //接收-第三步
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4) {
      var status = xhr.status;
      if (status >= 200 && status < 300) {
        options.success && options.success(xhr.responseText, xhr.responseXML);
      } else {
        options.error && options.error(status);
      }
    }
  }

  //连接和发送-第二步
  if (options.type == 'GET') {
    xhr.open('GET', options.url + '?' + params, true);
    xhr.send(null);
  } else if (options.type == 'POST') {
    xhr.open('POST', options.url, true);
    //设置表单提交时的内容类型
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(params);
  }
};









/**
 * @desc throttle 函数节流：所谓节流，就是指连续触发事件但在n秒内只执行一次函数。节流会稀释函数的执行频率
 * @param func 需要节流的函数
 * @param wait 延迟执行的毫秒数
 * @param type type=1为时间戳版本（立即执行）， type=2为定时器版（非立即执行）
 * **/
function throttle(func, wait = 200, type = 1) {
  if (type == 1) {
    let pre = 0;
    return function () {
      let _this = this;
      let args = arguments;
      let now = Date.now();
      if (now - pre > wait) {
        func.apply(_this, args);
        pre = now;
      }
    }
  } else {
    let timeout;
    return function () {
      let _this = this;
      let args = arguments;
      if (!timeout) {
        timeout = setTimeout(() => {
          func.apply(_this, args);
          timeout = null;
        }, wait);
      }
    }
  }
};


/** 
 * 时间格式化
*/
function formatDateTime(dt = new Date(), type) {
  let str = ""; //存储时间的字符串
  //获取年
  let year = dt.getFullYear();
  //获取月
  let month = dt.getMonth() + 1;
  //获取日
  let day = dt.getDate();
  //获取小时
  let hour = dt.getHours();
  //获取分钟
  let min = dt.getMinutes();
  //获取秒
  let sec = dt.getSeconds();
  //获取毫秒
  let ms = dt.getMilliseconds();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  hour = hour < 10 ? "0" + hour : hour;
  min = min < 10 ? "0" + min : min;
  sec = sec < 10 ? "0" + sec : sec;
  if (!type) {
    return str = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
  }
  if (type == 'yyyy-MM-dd') {
    return str = year + "-" + month + "-" + day;
  }
  if (type == 'yyyyMMddHHmmss') {
    return str = year + "" + month + "" + day + "" + hour + "" + min + "" + sec;
  }
  if (type == 'stamp') {
    let _num = (hour.toString() + min.toString() + sec.toString()) * 1 + ms;
    return _num;
  }
  return str = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
};


/** 
 * 本地存储封装
*/
function storageUtil(type = 'set', key, value) {
  if (device === 'pc') {
    if (type === 'set') {
      localStorage.setItem(key, JSON.stringify(value));
    } else if (type === 'get') {
      let val = localStorage.getItem(key);
      return val ? JSON.parse(val) : '';
    } else if (type === 'remove') {
      localStorage.removeItem(key);
    } else if (type === 'size') {
      if (!window.localStorage) {
        console.log('浏览器不支持localStorage');
        return 0;
      }
      var size = 0;
      if (key && localStorage.hasOwnProperty(key)) {
        size = localStorage.getItem(key).length;
      } else {
        for (let item in window.localStorage) {
          if (window.localStorage.hasOwnProperty(item)) {
            size += window.localStorage.getItem(item).length;
          }
        }
      }
      return (size / 1024).toFixed(2); // KB
    }
  } else if (device === 'wx') {
    if (type === 'set') {
      wx.setStorageSync(key, value);
    } else if (type === 'get') {
      let val = wx.getStorageSync(key);
      return val;
    } else if (type === 'remove') {
      wx.removeStorageSync(key);
    } else if (type === 'size') {
      if (wx && wx.getStorageInfoSync) {
        const res = wx.getStorageInfoSync();
        console.log('当前storage使用量' + res.currentSize + 'KB');
        return res.currentSize; // KB
      } else {
        console.log('当前版本不支持 wx.getStorageInfoSync()');
        return 0;
      }
    }
  }
};

/** 
 * 排序
 * order asce升序 desc 降序
*/
function bubbleSort(arr, order = 'asce') {
  let start = 0;
  let end = arr.length - 1;

  while (start < end) {
    let endPos = 0;
    let startPos = 0;

    for (let i = start; i < end; i++) {
      if (order === 'asce' ? arr[i] > arr[i + 1] : arr[i] < arr[i + 1]) {
        endPos = i;
        swap(arr, i, i + 1);
      }
    }
    end = endPos;
    for (let i = end; i > start; i--) {
      if (order === 'asce' ? arr[i - 1] > arr[i] : arr[i - 1] < arr[i]) {
        startPos = i;
        swap(arr, i - 1, i);
      }
    }
    start = startPos;
  }

  return arr;
};
function swap(arr, indexA, indexB) {
  [arr[indexA], arr[indexB]] = [arr[indexB], arr[indexA]];
};

/** 
 * 添加百度统计
*/
function AddBaiduTracking(url, customerid) {
  let scripts = document.getElementsByTagName("script");
  let baidu_url = false;
  for (let i = 0; i < scripts.length; i++) {
    let element = scripts[i];
    let _src = element.getAttribute('src');
    if(_src && _src.indexOf(url) !== -1) {
      baidu_url = true;
    }
  }
  if(!baidu_url) {
    var _hmt = _hmt || [];
    window._hmt = _hmt;
    let baidu_script = document.createElement("script");
    baidu_script.src = url;
    let _s = scripts[0]; 
    _s.parentNode.insertBefore(baidu_script, _s);
  }

  setTimeout(() => {
    if (window._hmt && customerid) { // 百度统计事件添加
      window._hmt.push(['_trackEvent','p_1_' + customerid, 'page', '']);
      // console.log('p_1_' + customerid);
    }
  }, 500);
};